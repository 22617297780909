<template>
  <v-card flat>
    <v-card-text>
      <v-row>
        <v-col v-if="!meal1"></v-col>
        <v-col v-if="meal1">
          {{ title_1 }}
          <h4>
            {{ meal1.name }} {{ meal1.diet }} {{ meal1.date }}
            <br/>
          </h4>
          M{{ meal1.id }} {{ meal1.sku }}
          {{ meal1.diet }}
          <v-row dense v-for="component of components" v-bind:key="component.id">
            <v-col cols=1 :class="isDifferentAmount(component.id)?'different':''">
              <span v-if="componentAmounts[component.id][meal1.id]">
                {{ formatWeightWithUnits(componentAmounts[component.id][meal1.id]) }}
              </span>
            </v-col>
            <v-col
              v-if="isComponentUsed(component.id, meal1.id) || isComponentUsed(component.id, meal2.id)"
              :class="!isComponentUsed(component.id, meal1.id)? 'not-found':''"
              class="">
              {{ component.name }} C({{ component.id }})
              <span>
              <template v-if="component.monosized">monosize</template>
              <template v-if="!component.monosized">
                small {{ Number(component.small_ratio).toFixed(2) }}
                large {{ Number(component.large_ratio).toFixed(2) }}
              </template>
              </span>
              <span v-if="meal2 && isComponentUsed(component.id, meal1.id) && isComponentDifferent(component.id)">
                <v-btn icon
                       @click="addComponent(component.id, meal2.id, componentAmounts[component.id][meal1.id], meal1.id)"
                >
                  <v-icon>mdi-arrow-right</v-icon>
                </v-btn>
              </span>
              <template v-if="isComponentDifferent(component.id)">
                <v-row style="line-height: 10px" dense
                       v-for="(ingredient, index) of getComponentIngredients(component.id, meal1)"
                       v-bind:key="index"
                       :class="componentDifference[component.id][ingredient.name] ? 'different' : ''"
                >
                  <v-col cols="1">
                    {{ formatWeightWithUnits(ingredient.amount) }}
                  </v-col>
                  <v-col>
                    {{ ingredient.name }}
                  </v-col>
                </v-row>
              </template>
            </v-col>

          </v-row>
        </v-col>
        <v-col v-if="!meal2"></v-col>
        <v-col v-if="meal2">
          {{ title_2 }}
          <h4>
            {{ meal2.name }} {{ meal2.diet }} {{ meal2.date }}
            <br/>
          </h4>
          M{{ meal2.id }} {{ meal2.sku }}
          {{ meal2.diet }}
          <v-row dense v-for="component of components" v-bind:key="component.id">
            <v-col cols=1 :class="isDifferentAmount(component.id)?'different':''">
              <span v-if="componentAmounts[component.id][meal2.id]">
                {{ formatWeightWithUnits(componentAmounts[component.id][meal2.id]) }}
              </span>
            </v-col>
            <v-col
              v-if="(meal1 && isComponentUsed(component.id, meal1.id)) || isComponentUsed(component.id, meal2.id)"
              :class="!isComponentUsed(component.id, meal2.id) ? 'not-found':''">
              {{ component.name }} C({{ component.id }})
              <span>
              <template v-if="component.monosized">monosize</template>
              <template v-if="!component.monosized">
                small {{ Number(component.small_ratio).toFixed(2) }}
                large {{ Number(component.large_ratio).toFixed(2) }}
              </template>
              </span>
              <span v-if="meal1 && !isComponentUsed(component.id, meal1.id)">
                <v-btn icon @click="removeComponent(component.id, meal2.id)"><v-icon>mdi-delete</v-icon></v-btn>
              </span>
              <template v-if="isComponentDifferent(component.id)">
                <v-row style="line-height: 10px" dense
                       v-for="(ingredient, index) of getComponentIngredients(component.id, meal2)"
                       v-bind:key="index"
                       :class="componentDifference[component.id][ingredient.name] ? 'different' : ''"
                >
                  <v-col cols="1">
                    {{ formatWeightWithUnits(ingredient.amount) }}
                  </v-col>
                  <v-col>
                    {{ ingredient.name }}
                  </v-col>
                </v-row>
              </template>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<style scoped>
.different {
  font-weight: bolder
}

.not-found {
  color: red;
  text-decoration: line-through;
}
</style>

<script>
import api from "@/api";
import {formatWeightWithUnits} from "@/store/utils";
import diff from 'microdiff';
import {mapGetters} from "vuex";

export default {
  name: "MealCompare",
  props: {
    title_1: {type: String, required: false},
    title_2: {type: String, required: false},
    meal_id_1: {type: Number, required: true},
    meal_id_2: {type: Number, required: true}
  },
  async mounted() {
    const audit = await api.get('v2/meal/audit', {
      params: {
        mealIds: [this.meal_id_1, this.meal_id_2],
        includeMealIngredients: true
      }
    })
      .then(({data}) => data);
    this.audit = audit;
    // console.log('audit', this.audit);
    this.meal1 = audit.meals[this.meal_id_1];
    this.meal2 = audit.meals[this.meal_id_2];

    this.meals[this.meal_id_1] = this.meal1;
    this.meals[this.meal_id_2] = this.meal2;

    if (this.meal1) this.meal1.ingredients.sort((a, b) => a.amount !== b.amount ? b.amount - a.amount : a.name.localeCompare(b.name));
    if (this.meal2) this.meal2.ingredients.sort((a, b) => a.amount !== b.amount ? b.amount - a.amount : a.name.localeCompare(b.name));

    this.components = audit.components;
    this.defaultComponents = audit.defaultComponents;
    this.componentAmounts = audit.componentAmounts;

    function getAmount(a) {
      const amounts = Object.values(audit.componentAmounts[a.id]).sort().reverse();
      return amounts[0];
    }

    this.components.sort((a, b) => getAmount(b) - getAmount(a));

    for (const component of this.components) {
      const toMap = ingredients => Object.fromEntries(ingredients.map(i => [i.name, formatWeightWithUnits(i.amount)]));
      const componentId = component.id;
      const componentIngredients1 = this.getComponentIngredients(componentId, this.meal1);
      const componentIngredients2 = this.getComponentIngredients(componentId, this.meal2);
      const difference = diff(toMap(componentIngredients1), toMap(componentIngredients2));
      // console.log('diff for ' + componentId, difference);
      const createOrRemoveDiffs = difference.filter(d => d.type !== 'CHANGE');
      const changeDiffs = difference.filter(d => d.type === 'CHANGE');
      this.componentDifference[componentId] = Object.fromEntries([
        ...createOrRemoveDiffs.map(d => ([d.path[0], true])),
        ...changeDiffs.map(d => ([d.path[0], true]))
      ]);
    }
  },
  data() {
    return {
      audit: null,
      meal1: null,
      meal2: null,
      components: null,
      componentAmounts: null,
      defaultComponents: null,
      componentDifference: {},
      meals: {},
    }
  },
  methods: {
    formatWeightWithUnits,
    isDifferentAmount(componentId) {
      const {componentAmounts, meal_id_1, meal_id_2} = this;
      return componentAmounts && componentAmounts[componentId][meal_id_1] !== componentAmounts[componentId][meal_id_2];
    },
    isComponentUsed(componentId, mealId) {
      const {componentAmounts} = this;
      return componentAmounts[componentId][mealId] !== undefined
    },
    getComponentIngredients(componentId, meal) {
      if (!meal) {
        return [];
      }
      // console.log('getComponentIngredients(componentId, meal)', componentId, meal);
      return meal.ingredients.filter(i => i.componentId === componentId);
    },
    replaceComponentIngredients(componentId, meal, newIngredients) {
      if (!meal) {
        console.error('no meal passed', componentId, meal, newIngredients);
        return;
      }

      const updatedIngredients = [
        ...meal.ingredients.filter(i => i.componentId !== componentId),
        ...newIngredients,
      ];
      console.log('updating ingredients', meal, updatedIngredients);
      this.$set(meal, 'ingredients', updatedIngredients);
    },
    isComponentDifferent(componentId) {
      return Object.keys(this.componentDifference[componentId]).length > 0;
    },
    async removeComponent(componentId, mealId) {
      console.log(`removing component ${componentId} from meal ${mealId}`);
      await api.delete(`/v2/meal/${mealId}/component/${componentId}`);
      await this.setMealAsSkuDefault(mealId);
      this.$emit('updated:meal', {mealId});
      const {componentAmounts} = this;
      this.$set(componentAmounts[componentId], mealId, undefined);
    },
    async addComponent(componentId, mealId, amount, sourceMealId) {
      console.log(`add ${amount} of component ${componentId} to meal ${mealId}`);
      await api.post(`/v2/meal/${mealId}/component/${componentId}`, {amount, source_meal_id: sourceMealId});
      await this.setMealAsSkuDefault(mealId);
      this.$emit('updated:meal', {mealId});
      const {componentAmounts, componentDifference} = this;
      this.$set(componentAmounts[componentId], mealId, amount);
      this.$set(componentDifference, componentId, {});
      const sourceMeal = this.meals[sourceMealId];
      this.replaceComponentIngredients(componentId, this.meals[mealId], this.getComponentIngredients(componentId, sourceMeal));
    },
    setMealAsSkuDefault(mealId) {
      const meal = this.meals[mealId];
      console.log(`setting meal ${mealId} to be SKU default ${meal.sku} of ${meal.name}`);
      mealId = Number(mealId);
      return api.put(`v2/meal/sku/${meal.sku}`, {selected_meal_id: mealId})
    },
  },
  computed: {
    ...mapGetters(['getDietColor', 'getDietName'])
  }
}
</script>