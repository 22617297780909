<template>
  <v-card flat :style="editAnyway ? 'background-color: pink':''">
    <v-dialog v-model="showReplaceDialog">
      <v-card min-height="800" min-width="600">
        <v-card-title>
          Choose another component
          <v-spacer/>
          <v-btn
            color="success"
            :disabled="!replacementComponent"
            @click="saveReplaceComponent"
          >
            Select Replacement
          </v-btn>
        </v-card-title>
        <v-card-actions>
          <ComponentAutocomplete
            must-have-component-foods
            :component.sync="replacementComponent"
          />
        </v-card-actions>
        <v-card-text>
          <ComponentDetail
            v-if="replacementComponent"
            :componentId="replacementComponent.id"
            read-only
            :is-admin="false"
            hide-meals
          />
        </v-card-text>

      </v-card>
    </v-dialog>
    <v-card-title>
      Meal Audit
      <v-spacer/>
      <v-btn-toggle v-model="view">
        <v-btn value="component">Component View</v-btn>
        <v-btn value="meal">Meal View</v-btn>
      </v-btn-toggle>
    </v-card-title>
    <v-card-text>
      This page is used to check upcoming meals for issues and helps you resolve those issues
    </v-card-text>
    <v-card-actions>
      <v-text-field
        v-model="search"
        label="Search"
        clearable
      />
      <v-spacer/>
      <v-text-field :value="datesFormatted"
                    persistent-hint
                    hint="Meal Dates"
                    label="Select dates"
                    single-line
                    readonly
                    @click="datePickerDate=dates[0]; showDatePicker=true"
                    append-icon="mdi-calendar"
      />
      <v-dialog
        v-model="showDatePicker"
        ref="dialog"
        width="290px"
        persistent
      >
        <v-date-picker
          v-model="datePickerDate"
          no-title
          @close="closeDatePicker"
        ></v-date-picker>
        <v-btn @click="closeDatePicker">Close</v-btn>
      </v-dialog>
    </v-card-actions>
    <template v-if="isComponentView">
      <v-card-title>Components</v-card-title>
      <v-card-text>
        <template v-if="loading">
          <v-container>
            <v-progress-circular indeterminate/>
          </v-container>
        </template>
        <v-alert v-if="componentsFiltered.length>0 && !isEditAllowed" type="warning">
          These meals are close to or past their production date. No edits allowed as customer orders for these meals
          may
          exist.
          <br/>
          Reasons include customers seeing ingredient lists, printing, and procurement.
          <br/>
          Meals after {{ dateFormatted(cutoffEditDate) }} can still be changed. This date increases by a week each
          Thursday.
          <br/>
          <v-btn type="danger" @click="editAnyway=true">IGNORE THIS WARNING AND EDIT ANYWAY</v-btn>
        </v-alert>
        <v-row v-for="component of componentsFiltered" v-bind:key="component.id">
          <v-col cols="3">
            <h3>
              <PrepTeamIcon :assignment="findAssignedTeam(component)"/>
              <router-link :to="{ name: 'ComponentDetail', params: { id : component.id }}">C{{
                  component.id
                }}
              </router-link>
              {{ component.name }}
            </h3>
            <h4>
              <template v-if="component.monosized">monosize</template>
              <template v-if="!component.monosized">
                small {{ Number(component.small_ratio).toFixed(2) }}
                large {{ Number(component.large_ratio).toFixed(2) }}
              </template>
            </h4>
            <p> {{ getTeamInstruction(component) }} </p>
            <p> {{ getMealsUsingComponent(component) }} meals using this component</p>
            <h5>Notes</h5>
            <p v-for="(line,i) of (component.notes||'').split('\n')" v-bind:key="i">
              {{ line }}
            </p>
          </v-col>
          <v-col cols="3">
            <v-alert type="warning" v-if="component.component_food_view.length===0">No Default Component</v-alert>
            <template v-if="component.component_food_view.length>0">
              <!--            <v-alert type="success">Default Component</v-alert>-->
              <h3>Default Component</h3>
            </template>
            <ul>
              <li v-for="(ingredient,index3) of defaultComponents[component.id]" v-bind:key="index3">
                <b>{{ ingredient.amount }}g {{ ingredient.name }}</b>
                <div v-if="ingredient.team">team: {{ ingredient.team }}</div>
                <div v-if="ingredient.instruction">instruction: {{ ingredient.instruction }}</div>
              </li>
            </ul>
          </v-col>
          <v-col cols="6">
            <v-row v-for="(variation,mealId) of variations[component.id]" v-bind:key="mealId">
              <v-col>
                <v-toolbar flat dense>
                  <v-btn
                    :disabled="!isEditAllowed"
                    class="mr-2"
                    v-if="!isDefault(component,mealId)"
                    @click="makeDefault(component,mealId)">
                    Set as Default
                  </v-btn>
                  <v-btn
                    :disabled="!isEditAllowed"
                    class="mr-2"
                    v-if="hasDefault(component) && !isDefault(component,mealId)"
                    @click="setMealToDefault(component,mealId)"
                  >
                    Overwrite With Default
                  </v-btn>
                  <v-btn
                    :disabled="!isEditAllowed"
                    class="mr-2"
                    @click="makeNewComponent(component,mealId)"
                  >
                    Make into a new Component
                  </v-btn>
                  <v-btn
                    :disabled="!isEditAllowed"
                    class="mr-2"
                    @click="replaceComponent(component,mealId)"
                  >
                    Replace
                  </v-btn>
                </v-toolbar>

                <b>{{ formatWeightWithUnits(componentAmounts[component.id][mealId]) }}</b> is used in
                <v-chip v-if="meals[mealId].diet" text-color="white" small
                        :color="getDietColor(getDietIdFromStream(meals[mealId].diet))">
                  {{ getDietName(getDietIdFromStream(meals[mealId].diet)) }}
                </v-chip>
                {{ meals[mealId].name }}
                <v-icon color="yellow" v-if="meals[mealId].is_selected">mdi-star</v-icon>
                <br/>

                <router-link :to="{ name: 'MealDesign', params: { id: mealId}}">M{{ mealId }}</router-link>
                {{ meals[mealId].date }}


                <router-link :to="{ name: 'MealVersions', query: { sku: meals[mealId].sku}}">
                  {{ meals[mealId].sku }}
                </router-link>

                <v-alert v-if="isDefault(component,mealId)" type="success">
                  Using Default
                </v-alert>
                <!--                <ul v-if="differences[component.id][index]">-->
                <!--              <ul v-if="differences[component.id]">-->
                <!--                <li v-for="(ingredient,index2) of differences[component.id][mealId]" v-bind:key="index2">-->
                <!--                  <i>{{ ingredient.amount }}g {{ ingredient.name }}</i> {{ ingredient.instruction }}-->
                <!--                  <div v-if="ingredient.team">team: {{ ingredient.team }}</div>-->
                <!--                  <div v-if="ingredient.instruction">instruction: {{ ingredient.instruction }}</div>-->
                <!--                </li>-->
                <!--              </ul>-->
                <!--              <ul v-if="!differences[component.id]">-->
                <v-card flat v-if="!isDefault(component,mealId)">
                  <v-card-text>
                    <div>a 100g portion consists of</div>
                    <ul>
                      <li
                        v-for="(ingredient,index2) of variation" v-bind:key="index2"
                        :style="isChanged(component,mealId,ingredient.name) ? 'font-weight: bolder' : ''">

                        {{ ingredient.amount }}g {{ ingredient.name }}
                        <span class="caption"> {{ ingredient.instruction }} </span>
                        <div v-if="ingredient.team">team: {{ ingredient.team }}</div>
                        <div v-if="ingredient.instruction">instruction: {{ ingredient.instruction }}</div>
                      </li>
                      <li v-for="(ingredient,index3) of getRemoved(component,mealId)" v-bind:key="`removed-${index3}`">
                  <span class="text-decoration-line-through red--text">
                    {{ ingredient.name }}</span>
                      </li>
                    </ul>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-col>

        </v-row>

      </v-card-text>
    </template>
    <template v-if="isMealView">
      <v-card-title>Comparing Meal Changes</v-card-title>
      <v-card-text v-for="(meal) of mealsFiltered" v-bind:key="meal.id">
        <MealCompare
          title_1="Previous"
          :meal_id_1="meal.previous_meal_id || 0"
          title_2="Current"
          :meal_id_2="meal.id"
        />
      </v-card-text>
    </template>
  </v-card>
</template>refuse_name

<style scoped>

</style>

<script>
import moment from "moment/moment";
import api from "@/api";
import urlState from "@/router/urlState";
import {mapActions, mapGetters} from "vuex";
import PrepTeamIcon from "@/components/PrepTeamIcon.vue";
import ComponentAutocomplete from "@/components/ComponentAutocomplete.vue";
import ComponentDetail from "@/components/ComponentDetail.vue";
import {dateFormatted, formatDate, formatWeightWithUnits, getNextOrderingDate, getProductionDays} from "@/store/utils";
import MealCompare from "@/components/MealCompare.vue";

export default {
  name: "MealAudit",
  components: {MealCompare, ComponentDetail, ComponentAutocomplete, PrepTeamIcon},
  mixins: [urlState],
  data() {
    return {
      dates: [],
      showDatePicker: null,
      datePickerDate: null,
      variations: null,
      components: [],
      differences: null,
      defaultComponents: null,
      meals: null,
      search: null,
      showReplaceDialog: null,
      replaceComponentId: null,
      replaceMealId: null,
      replacementComponent: null,
      componentAmounts: null,
      loading: null,
      cutoffEditDate: null,
      editAnyway: null,
      previousMealIds: null,
      previousMeals: null,
      view: 'component'
    }
  },
  async mounted() {
    const nextOrderingDate = getNextOrderingDate();
    console.log('next ordering date', nextOrderingDate);
    this.cutoffEditDate = moment(nextOrderingDate).add(1, 'week');

    this.syncToUrl({
      param: 'dates', urlParam: 'dates', initFromRoute: true,
      parseCallback: (v) => Array.isArray(v) ? v : [v]
    });
    this.syncToUrl({
      param: 'search', urlParam: 'search', initFromRoute: true,
    });
    this.syncToUrl({
      param: 'view', urlParam: 'view', initFromRoute: true,
    });

    await this.fetchDiets();
    this.$nextTick(() => this.fetchData());
  },
  computed: {
    ...mapGetters(['getDietColor', 'getDietName', 'getDietIdFromStream']),
    datesFormatted() {
      const format = 'dddd MMMM D';
      if (!this.dateFrom) {
        return '';
      } else if (this.dateFrom === this.dateTo) {
        return `${moment(this.dateFrom).format(format)}`;
      } else {
        return `${moment(this.dateFrom).format(format)} - ${moment(this.dateTo).format(format)}`
      }
    },
    dateFrom() {
      return [...this.dates].sort()[0];
    },
    dateTo() {
      return [...this.dates].sort().reverse()[0];
    },
    componentsFiltered() {
      if (this.search) {
        const regEx = new RegExp(this.search, 'i');
        return this.components.filter(c => regEx.test(c.name))
      } else {
        return this.components;
      }
    },
    mealsFiltered() {
      if (this.search) {
        const regEx = new RegExp(this.search, 'i');
        return Object.fromEntries(
          Object.values(this.meals || {})
            .filter(c => regEx.test(c.name))
            .map(m => ([m.id, m]))
        );
      } else {
        return this.meals;
      }
    },
    isDateClose() {
      const date = this.dates[0];
      return date && moment(date).isSameOrBefore(moment().add(1, 'week'));
    },
    isEditAllowed() {
      return (this.cutoffEditDate && this.cutoffEditDate.isBefore(this.dateTo)) || this.editAnyway;
    },
    isComponentView() {
      return this.view === 'component';
    },
    isMealView() {
      return this.view === 'meal';
    },
  },
  methods: {
    dateFormatted,
    formatWeightWithUnits,
    ...mapActions(['createComponent', 'fetchDiets']),
    closeDatePicker() {
      this.showDatePicker = false;
      this.dates = getProductionDays(this.datePickerDate);
      console.log('setting dates', this.dates);
      this.loading = true;
      this.components = [];
      return this.fetchData();
    },
    fetchData() {
      console.log('fetch data');
      // this.editAnyway = false;
      const {dateFrom, dateTo} = this;
      if (dateFrom && dateTo) {
        api.get('v2/meal/audit', {params: {dateFrom, dateTo, includePreviousMealVersion: true}})
          .then(r => {
            const data = r.data;
            console.log('got r', data);
            this.components = data.components.sort((a, b) => a.name.localeCompare(b.name));
            this.componentAmounts = data.componentAmounts;
            this.defaultComponents = data.defaultComponents;
            this.variations = data.variations;
            this.differences = data.differences;
            this.meals = data.meals;
            this.loading = false;
          });
      }
    },
    hasDefault(component) {
      return component.component_food_view.length > 0;
    },
    isDefault(component, mealId) {
      const {differences} = this;
      return this.hasDefault(component)
        && !(differences[component.id]
          && differences[component.id][mealId]
          && differences[component.id][mealId].length > 0);
    },
    makeDefault(component, mealId) {
      return api.post(`v2/component/${component.id}/copy-default-from-meal/${mealId}`)
        // set this to be the sku - as sku might not be using the default!
        .then(r => {
          console.log('updated', r);
          this.setMealAsSkuDefault(mealId);
        })
        // reload after update
        .then(() => this.fetchData())
        .catch(e => console.error('failed', e));
    },
    setMealAsSkuDefault(mealId) {
      const meal = this.meals[mealId];
      console.log(`setting meal ${mealId} to be SKU default ${meal.sku} of ${meal.name}`);
      mealId = Number(mealId);
      return api.put(`v2/meal/sku/${meal.sku}`, {selected_meal_id: mealId})
    },
    setMealToDefault(component, mealId) {
      // copy the component ingredients over
      const amount = this.componentAmounts[component.id][mealId];
      return api.post(`v2/component/${component.id}/copy-default-to-meal/${mealId}`, {amount})
        .then(r => {
          console.log('updated', r);
          this.setMealAsSkuDefault(mealId);
        })
        // reload after update
        .then(() => this.fetchData())
        .catch(e => console.error('failed', e));
    },
    async makeNewComponent(component, mealId) {
      const newName = prompt('Enter a new name for this variation of the component.  To avoid confusion, make the name unique.', component.name);

      if (newName === null) {
        console.log('cancelled');
        return;
      }
      if (newName === component.name) {
        alert('That is the same as the original component name');
        return;
      }
      console.log('new name for ' + mealId + ' version of ' + newName);

      api.post(`v2/component/${component.id}/create-component-from-meal/${mealId}`, {name: newName})
        .then(r => {
          console.log('updated', r);
          this.setMealAsSkuDefault(mealId);
        })
        // reload after update
        .then(() => this.fetchData())
        .catch(e => console.error('failed', e));
    },
    getMealsUsingComponent(component) {
      return (component.meal_ids_using_component || []).length;
    },
    getTeamInstruction(component) {
      return (component.component_steps || [])
        .filter(({instruction}) => !!instruction)
        .map(({team, instruction}) => `${team}` + (instruction ? `: ${instruction}` : ''))
        .join(' ');
    },
    findAssignedTeam(componentOrMeal) {
      if (!componentOrMeal) {
        return '';
      } else if (componentOrMeal.meal) {
        const assignment = componentOrMeal.meal && componentOrMeal.meal.meal_component_steps.find(mc => mc.component_id === this.component.id);
        return assignment && assignment.team
      } else {
        // console.log('find assigned team for component', componentOrMeal.component_steps)
        const [assignment] = componentOrMeal.component_steps && componentOrMeal.component_steps;
        return assignment && assignment.team
      }
    },
    isChanged(component, mealId, ingredientName) {
      if (this.differences[component.id] && this.differences[component.id][mealId]) {
        return this.differences[component.id][mealId].find(i => i.name === ingredientName);
      } else {
        return false;
      }
    },
    getRemoved(component, mealId) {
      if (this.differences[component.id] && this.differences[component.id][mealId]) {
        return this.differences[component.id][mealId].filter(i => i.removed);
      } else {
        return [];
      }
    },
    replaceComponent(component, mealId) {
      this.showReplaceDialog = true;
      this.replaceComponentId = component.id;
      this.replaceMealId = mealId;
    },
    async saveReplaceComponent() {
      const mealId = this.replaceMealId;
      const meal = this.meals[mealId];
      const oldComponentId = this.replaceComponentId;
      const oldComponent = this.components.find(c => c.id === oldComponentId);
      const newComponentId = this.replacementComponent.id;
      const newComponent = this.replacementComponent;
      const amount = Number(this.componentAmounts[oldComponentId][mealId]);
      console.log(`replacing ${amount}g of ${oldComponent.name} with ${newComponent.name} in ${meal.name}`, this.replacementComponent);
      this.showReplaceDialog = false;
      // first delete current component
      if (!confirm(`replacing ${amount}g of \n"${oldComponent.name}" with "${newComponent.name}" \nin ${meal.name}\n\nARE YOU SURE?`)) {
        console.log('cancelled')
      } else {
        await api.delete(`/v2/meal/${mealId}/component/${oldComponentId}`);
        await api.post(`/v2/meal/${mealId}/component/${newComponentId}`, {amount});
        await this.setMealAsSkuDefault(mealId);
        this.fetchData();
      }
    },
    formatDate,
    getNextOrderingDate
  }
}
</script>
